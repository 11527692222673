import React, { useMemo } from "react";
import { styled, theme } from "@puzzle/theme";
import * as Stitches from "@stitches/react";

const horizontalStyles = {
  gridTemplateColumns: "$$termWidth 1fr",
  gridAutoFlow: "column",

  // FIXME debatable default..?
  alignItems: "center",
};

const verticalStyles = {
  gridTemplateColumns: "none",
  gridAutoFlow: "row",
};

const Item = styled("div", {
  display: "grid",
});

const Term = styled("dt", {
  fontWeight: "$bold",
  fontSize: "13px",
  lineHeight: "18px",
  letterSpacing: "0.2px",
  color: "$gray500",
  display: "flex",
  alignItems: "center",
  gap: "$1",
});

const Description = styled("dd", {
  fontSize: "13px",
  lineHeight: "18px",
  color: "$neutral100",
  margin: 0,
  overflow: "hidden",
});

const Root = styled("dl", {
  $$termWidth: "120px",
  $$itemGap: "$space$1h",

  display: "flex",
  flexDirection: "column",
  gap: "$1h",
  margin: 0,

  [`${Item}`]: {
    gap: "$$itemGap",
  },

  defaultVariants: {
    direction: "horizontal",
  },

  variants: {
    direction: {
      horizontal: {
        [`${Item}`]: horizontalStyles,
      },

      vertical: {
        "&&": verticalStyles,
      },
    },
  },
});

// Inspired by dl/dd/dt and https://www.patternfly.org/v4/components/description-list
export const DescriptionList = ({
  children,
  items,
  css: _css,
  itemGap,
  termWidth,
  termCss,
  direction = "horizontal",
  ...props
}: React.ComponentPropsWithoutRef<typeof Root> & {
  // TODO Maybe objects were a better idea. Or nested JSX.
  items?: [React.ReactNode, React.ReactNode][];
  itemGap?: keyof typeof theme.space;
  // TODO Try to measure best column width
  termWidth?: number | string;
  termCss?: Stitches.CSS;
}) => {
  const css = useMemo(() => {
    const result: Stitches.CSS = { ..._css };

    if (itemGap) {
      result["$$itemGap"] = `$space$${itemGap}`;
    }
    if (termWidth) {
      result["$$termWidth"] = termWidth;
    }

    return result;
  }, [_css, itemGap, termWidth]);

  return (
    <Root {...props} css={css} direction={direction} data-direction={direction}>
      {items
        ? items.map(([term, description], i) => (
            <Item key={i}>
              <Term css={termCss}>{term}</Term>
              <Description>{description}</Description>
            </Item>
          ))
        : children}
    </Root>
  );
};

DescriptionList.toString = Root.toString;
DescriptionList.Item = Item;
DescriptionList.Term = Term;
DescriptionList.Description = Description;
